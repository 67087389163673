import {inject} from '@angular/core'
import {Router} from '@angular/router'
import {from, Observable, of} from 'rxjs'
import {ConfigService} from '../services/config.service'

export const routeResolver = (
  service: ConfigService,
  router: Router,
  window: Window
): Observable<boolean> => {
  const location = window.location

  const onInternalRoute = location.hostname.includes('internal')
  const onSparaRoute = location.hostname.includes('spara')

  service.onSparaRoute = onSparaRoute
  service.onInternalRoute = onInternalRoute

  if (onInternalRoute) {
    return from(router.navigate(['/admin']))
  }

  return of(true)
}

export const internetbankenResolver = () => {
  const service = inject(ConfigService)
  service.onInternetBankRoute = true
}

