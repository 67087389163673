import {Routes} from '@angular/router'
import {isOnInternalDomain} from './application/auth-guard'
import {internetbankenResolver} from './application/route-resolver'
import {bootstrapResolver} from './services/config.service'

export default [
  {
    path: '',
    pathMatch: 'full',
    loadComponent: () => import('./home/home.component').then(m => m.HomeComponent)
  },
  {
    path: 'neos',
    loadComponent: () => import('./neos/neos.component').then(m => m.NeosComponent)
  },
  {
    path: 'internetbanken',
    loadComponent: () => import('./home/home.component').then(m => m.HomeComponent),
    resolve: [internetbankenResolver]
  },
  {
    path: 'admin',
    loadComponent: () => import('./home/home.component').then(m => m.HomeComponent),
    canActivate: [isOnInternalDomain],
    resolve: [bootstrapResolver]
  },
  {
    path: '**',
    redirectTo: ''
  }
] satisfies Routes
